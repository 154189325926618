<template>
  <div class="bg-primary py-5">
    <b-container>
      <div class="d-flex justify-content-between align-items-center mb-5">
        <h2 class="font-size-21 font-weight-bold text-white ibbil-vertical-light-line position-relative">{{ $t('endUser.shops') }}</h2>
        <div class="justify-left">
          <button
              class="bg-transparent border border-warning text-white font-bold py-2 px-4 iq-border-radius-5 "
          >
            <router-link :to="{name: 'stores'}" tag="span">{{ $t('endUser.seeAll') }}</router-link>
          </button>
        </div>
      </div>
      <div class="position-relative">
        <Swiper :id="`post-slider-list-of-stores`"  :options="swiperOptions" :pagination="false" v-if="!loadingStores">
          <swiperSlide class="stores-slide" v-for="(category, key) in allCategories" :key="category.id">
              <router-link v-if="key + 1 != allImageStore.length" :to="{name: 'allMarketplaces' , params: {id:category.slug}}" tag="section" class="iq-border-radius-10 overflow-hidden">
              <div class="h-64 overflow-hidden">
<!--                <img :src="category.cover  ? category.cover : defaultImg" class="w-100 h-100"  :alt="category.name" />-->
                <img :src="allImageStore[key]" class="w-100 h-100"  :alt="category.name" />
              </div>
              <p class="text-center text-black font-size-20 py-2" >{{category.name}}</p>
            </router-link>
            <router-link v-else to="/stores/merchants/electronic_chip/store-profile/e_ship/" tag="section" class="iq-border-radius-10 overflow-hidden">
              <div class="h-64 overflow-hidden">
                <!--                <img :src="category.cover  ? category.cover : defaultImg" class="w-100 h-100"  :alt="category.name" />-->
                <img :src="allImageStore[key]" class="w-100 h-100"  :alt="category.name" />
              </div>
              <p class="text-center text-black font-size-20 py-2" >{{category.name}}</p>
            </router-link>
          </swiperSlide>
        </Swiper>
        <div v-else>
          <spinner-loading text="Loading Store..."/>
        </div>
        <div class="arrowsStore">
          <span class="swiper-button-next stories-swiper-button-next top-50 position-absolute z-2 ">
            <img :src="require('@/assets/images/ibbil/icon-arrow-warning.svg')" />
          </span>
          <span class="swiper-button-prev stories-swiper-button-prev swiper-button-no-rotate top-50 position-absolute z-2 ">
           <img :src="require('@/assets/images/ibbil/icon-arrow-warning.svg')" />
          </span>
        </div>
      </div>
    </b-container>
  </div>
</template>
<script>
import marketplace from '@/modules/servicePages/marketplace/services/marketplace'

export default {
  data () {
    return {
      allCategories: [],
      allImageStore: [require('@/assets/images/PartitionsLogos/store.png'), require('@/assets/images/PartitionsLogos/allaf.png'), require('@/assets/images/PartitionsLogos/montgat.png'), require('@/assets/images/PartitionsLogos/feed.png'), require('@/assets/images/PartitionsLogos/accessories.png'), require('@/assets/images/PartitionsLogos/accessories.png'), require('@/assets/images/PartitionsLogos/electric.png')],
      loadingStores: false,
      defaultImg: require('@/assets/images/ibbil/ibbil-cover.jpg'),
      swiperOptions: {
        centeredSlides: false,
        spaceBetween: 10,
        autoplay: false,
        slidesPerView: 3.5,
        // dir: 'ltr',
        pagination: {
          clickable: true,
          // el: '.swiper-pagination',
          type: 'bullets'
        },
        loop: false,
        breakpoints: {
          0: {
            slidesPerView: 1,
            spaceBetween: 15
          },
          480: {
            slidesPerView: 1.5,
            spaceBetween: 15,
            centeredSlides: true
          },
          768: {
            slidesPerView: 2,
            spaceBetween: 15,
            centeredSlides: false
          },
          992: {
            slidesPerView: 3.5,
            spaceBetween: 30,
            centeredSlides: false
          }
        },
        // Navigation arrows
        navigation: {
          nextEl: '.stories-swiper-button-next',
          prevEl: '.stories-swiper-button-prev'
        }
      }
    }
  },
  methods: {
    getAllStores () {
      this.loadingStores = true
      marketplace.getAllStoresCategories().then(res => {
        this.allCategories = res.data.data
      }).finally(() => {
        this.loadingStores = false
      })
    }
  },
  created () {
    this.getAllStores()
  }
}
</script>
<style lang="scss">
.arrowsStore .swiper-button-next{
  cursor: pointer;
  left: -45px;
}
.arrowsStore .swiper-button-prev {
  cursor: pointer;
  right: -45px;
  left: auto;
}
.arrowsStore .swiper-button-prev img {
  transform: rotateY(180deg);
}
.arrowsStore img {
  width: 35px;
}
.h-64 {
  height: 80rem;
}
#post-slider-list-of-stores .swiper-wrapper .swiper-slide  {
  display: block !important;
}
.swiper-button-disabled {
  opacity: 0.3;
}
</style>
